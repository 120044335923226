import { Env } from "@stencil/core";

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = JSON.parse(Env.firebaseConfig);

export const firebase = initializeApp(firebaseConfig);

export const auth = getAuth(firebase);
export const storage = getStorage(firebase);
export const database = getDatabase(firebase);
export const firestore = getFirestore(firebase);
export const messaging = getMessaging(firebase);
